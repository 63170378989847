
import { ApiGetCourseParticipantDto } from "@/api/generated/Api";
import TheCourseButtonList from "@/components/courses/list/TheCourseButtonList.vue";
import TheCourseTitleList from "@/components/courses/list/TheCourseTitleList.vue";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "TheCourseTableList",
  components: { TheCourseTitleList, TheCourseButtonList },
  props: {
    header: {
      type: Array,
      required: true,
    },
    courseList: {
      type: Array as PropType<ApiGetCourseParticipantDto[]>,
      required: true,
    },
    finished: {
      type: Boolean,
      required: false,
    },
    search: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      formatLocalizedDate,
    };
  },
});
