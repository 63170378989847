
import { defineComponent } from "@vue/composition-api";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "TheCancelParticipationModal",
  props: {
    courseId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters("course", ["getCourse", "getParticipants"]),
  },
  methods: {
    ...mapActions("course", ["fetchCourse", "fetchCourses", "fetchParticipant", "postParticipant"]),
    async handleCancelling() {
      const customerId = JSON.parse((localStorage as any).getItem("customer"));
      const participantRole = this.getParticipants[0].roleName;
      await this.postParticipant({
        userId: customerId,
        courseId: this.courseId,
        roleName: participantRole,
        status: "avmeldt",
      });
      await this.fetchCourses();
      this.$emit("close");
    },
  },
  async mounted() {
    const customerId = JSON.parse((localStorage as any).getItem("customer"));
    await this.fetchCourse({
      courseId: this.courseId,
    });
    await this.fetchParticipant({
      courseId: this.courseId,
      userId: customerId,
    });
  },
});
