
import TheCancelParticipationModal from "@/components/courses/list/TheCancelParticipationModal.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { useRouter } from "@/shared/useHelpers";
import { setSingleCourseStorageData } from "@/shared/helpers/courseStorageHelper";
import { defineComponent } from "@vue/composition-api";
import { StatusType } from "@/shared/enums/courseApplicationStatus.enums";

export default defineComponent({
  name: "TheCourseButtonList",
  components: {
    BaseModal,
    TheCancelParticipationModal,
  },
  props: {
    courseId: {
      type: Number,
      default: 0,
    },
    deadlineDate: {
      type: String,
      required: false,
    },
    userIsResource: {
      type: Boolean,
      required: false,
    },
    userStatus: {
      type: String,
      required: true,
    },
    appliedViaForm: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const goToAttendance = () => {
      router.push(`/course/${props.courseId}/attendance`);
    };

    const goToHours = () => {
      router.push(`/course/${props.courseId}/hours`);
    };

    const goToCourseDetails = () => {
      setSingleCourseStorageData({
        id: props.courseId,
        userStatus: props.userStatus as StatusType,
        appliedViaForm: props.appliedViaForm,
      });
      router.push({
        name: "singleCourse",
        params: {
          id: props.courseId.toString(),
          userStatus: props.userStatus,
          appliedViaForm: props.appliedViaForm.toString(),
        },
      });
    };

    return { isMobile, goToAttendance, goToHours, goToCourseDetails };
  },
});
