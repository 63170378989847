var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([!_vm.isMobile ? {
      key: "title",
      fn: function () {
        return [_vm._v("Kurs og studier")];
      },
      proxy: true
    } : null, {
      key: "actions",
      fn: function () {
        return [_c('v-text-field', {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function () {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#active"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-playlist-check")]), _vm._v(" Aktive ")], 1), _c('v-tab', {
          attrs: {
            "href": "#inactive"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-text")]), _vm._v(" Avsluttede ")], 1)], 1)];
      },
      proxy: true
    }], null, true)
  }, [[_c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "active"
    }
  }, [_vm.activeCourses ? _c('TheCourseTableList', {
    attrs: {
      "header": _vm.isMobile ? _vm.mobileHeaders : _vm.tableHeaders,
      "courseList": _vm.activeCourses,
      "search": _vm.search
    }
  }) : _vm._e()], 1), _c('v-tab-item', {
    attrs: {
      "value": "inactive"
    }
  }, [_vm.inactiveCourses ? _c('TheCourseTableList', {
    attrs: {
      "header": _vm.isMobile ? _vm.mobileHeaders : _vm.tableHeaders,
      "courseList": _vm.inactiveCourses,
      "search": _vm.search
    }
  }) : _vm._e()], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }