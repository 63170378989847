
import { api } from "@/api/api";
import { ApiGetCourseParticipantDto } from "@/api/generated/Api";
import TheCourseTableList from "@/components/courses/list/TheCourseTableList.vue";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { RolesType } from "@/shared/enums/roleTypesEnum.enums";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { FormatList } from "@/shared/helpers/filterHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRouter } from "@/shared/useHelpers";
import { CourseInstructorTableColumns } from "@/shared/utils/tableHeaders";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

enum ActiveCoursesStatus {
  planned = "planlagt",
  open = "åpnet",
}

enum InactiveCourseStatus {
  closed = "avsluttet",
  delayed = "utsatt",
}

enum InvalidUserRegistrationStatus {
  leave = "permisjon",
  interested = "interessert",
  set = "innstilt",
  deleted = "slettet",
}

export default defineComponent({
  name: "courseListPage",
  components: { BaseLayout, TheCourseTableList },
  props: {
    routeTab: String,
  },
  setup(props) {
    const search = ref<string>("");
    const courses = ref<ApiGetCourseParticipantDto[]>();
    const tableHeaders = FormatList(CourseInstructorTableColumns, "text");
    const activeCourses = ref<ApiGetCourseParticipantDto[]>([]);
    const inactiveCourses = ref<ApiGetCourseParticipantDto[]>([]);

    const router = useRouter();
    const currentTab = computed({
      set: (tab: string) => {
        router.replace({ params: { tab } });
      },
      get: () => props.routeTab || "active",
    });

    const fetctCourses = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const courses = (await api.course.getCourseParticipantAndCoursesByCurrentUserAsync()).data.filter(
          (item) => item.roleName === RolesType.Student
        );
        allocateCourses(courses);
      });
    };

    const allocateCourses = (courses: ApiGetCourseParticipantDto[]) => {
      courses.map((x) => {
        if (
          Object.values(ActiveCoursesStatus).includes(x.course?.status as ActiveCoursesStatus) &&
          !Object.values(InvalidUserRegistrationStatus).includes(x.status as InvalidUserRegistrationStatus)
        ) {
          activeCourses.value.push(x);
        } else if (
          Object.values(InactiveCourseStatus).includes(x.course?.status as InactiveCourseStatus) &&
          !Object.values(InvalidUserRegistrationStatus).includes(x.status as InvalidUserRegistrationStatus)
        ) {
          inactiveCourses.value.push(x);
        }
      });
    };

    onMounted(() => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await fetctCourses();
      });
    });

    return {
      search,
      courses,
      tableHeaders,
      RolesType,
      activeCourses,
      inactiveCourses,
      currentTab,
      mobileHeaders,
      isMobile,
    };
  },
});

const mobileHeaders = [
  {
    text: "Tittel",
    value: "course.externalTitle",
  },
  {
    text: "Handlinger",
    value: "action",
  },
  {
    text: "Status",
    value: "course.status",
  },
  {
    text: "Min status",
    value: "status",
  },
];
