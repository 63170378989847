var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    staticClass: "mt-2",
    attrs: {
      "headers": _vm.header,
      "items": _vm.courseList,
      "sort-by": ['course.status', 'courseId'],
      "sort-desc": [false, true],
      "search": _vm.search,
      "data-cy": "courseTable"
    },
    scopedSlots: _vm._u([{
      key: "item.course.startDate",
      fn: function (_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDate(value)) + " ")];
      }
    }, {
      key: "item.course.endDate",
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDate(value)) + " ")];
      }
    }, {
      key: "item.status",
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value === "kvalifisert" || value === "godkjent" ? "søkt" : value === "avslått" ? " " : value) + " ")];
      }
    }, {
      key: "item.action",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('TheCourseButtonList', {
          attrs: {
            "courseId": item.courseId,
            "deadlineDate": item.course.cancellationDeadline,
            "userStatus": item.status,
            "userIsResource": item.isResource,
            "appliedViaForm": item.appliedViaForm,
            "data-cy": "courseActions"
          }
        })];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }