var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('v-icon', {
    staticClass: "mr-6",
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-alert-circle-outline")]), _vm._v(" Avbryt deltakelse i studie ")], 1), _c('v-card-text', [_c('p', [_vm._v(" Du er i ferd med å avbryte deltakelse i følgende studier: "), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.getCourse.courseName))])])]), _c('v-card-text', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Denne handlingen kan ikke omgjøres. ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey darken-3",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Tilbake ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.handleCancelling
    }
  }, [_vm._v(" Bekrefte avbestilling ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }