var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([_vm.userIsResource ? {
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "data-cy": "attendanceButton",
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.goToAttendance();
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-account-multiple-outline")])], 1)];
      }
    } : null], null, true)
  }, [_c('span', [_vm._v("Frammøte")])]), _vm.userIsResource ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "data-cy": "attendanceButton",
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.goToAttendance();
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-account-multiple-outline")])], 1)];
      }
    }], null, false, 1711077158)
  }, [_c('span', [_vm._v("Se søknad")])]) : _vm._e(), _vm.userIsResource ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "data-cy": "hoursButton",
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.goToHours();
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-timer-sand-empty ")])], 1)];
      }
    }], null, false, 2776490653)
  }, [_c('span', [_vm._v("Se søknad")])]) : _vm._e(), !_vm.isMobile ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "data-cy": "viewApplication",
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.goToCourseDetails();
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-file-document-outline")])], 1)];
      }
    }], null, false, 1190796064)
  }, [_c('span', [_vm._v("Åpne kurs")])]) : _vm._e(), _vm.isMobile ? _c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToCourseDetails();
      }
    }
  }, [_vm._v(" Vis mer ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }